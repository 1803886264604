<template>
  <b-modal id="quick-call-modal" size="md" modal-class="modal-right-pane">
    <template v-slot:modal-header="{ close }">
      <h2>{{ $t('quick_call') }}</h2>
      <a class="pointer position-relative modal-close" @click="close()">
        <CloseSvg/>
      </a>
    </template>
    <div class="mt-4 evaluation-block">
      <div class="font-weight-bold mb-2">{{ $t('link_for_students') }}:</div>
      <div class="wrap-link card card-gray">
        {{ studentLink }}
      </div>
      <div class="text-right">
        <button class="btn btn-themed btn-white btn-secondary mt-3" @click="copy">{{ $t('copy') }}</button>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button class="btn btn-themed" @click="start">{{ $t('start') }}</b-button>
    </template>
  </b-modal>
</template>

<script>
import {mapState} from "vuex"
import CloseSvg from '@/assets/svg/close.svg'

export default {
  name: "QuickCallModal",
  components: {
    CloseSvg
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    hostLink() {
      return this.generateLink(true)
    },
    studentLink() {
      return this.generateLink(false)
    }
  },
  methods: {
    generateLink(host) {
      if(!this.user) return ''
      let userId = this.user.id
      // let userName = host ? this.user.name : 'guest'
      let userName = host ? this.user.name : ''
      let params = {
        room: 'simple-'+userId+'-0',
        timestamp: Date.now(),
        host: host,
        userId: userId,
        name: userName,
        callType: 'quickcall'
      }
      params = new URLSearchParams(params).toString()
      return `https://sfu-vpc.study.enggo.kz/${btoa(params)}`
      // return `https://pssfu.study.enggo.kz/${btoa(params)}`
    },
    copy() {
      let _this = this
      navigator.clipboard.writeText(this.studentLink).then(function() {
        _this.$success(_this.$t('copied'))
      }, function(err) {
        console.log(err)
      });
    },
    start() {
      window.location = this.hostLink
    }
  }
}
</script>

<style scoped lang="scss">
.wrap-link {
  word-break: break-all;
}
.card-gray {
  background: #F8F9FB;
  border-radius: 16px;
  border: none;
  padding: 32px;
}
</style>

<template>
  <div>
    <VideoModal v-if="videoModalOpen"/>
    <section class="home">
      <b-row class="align-items-center mb-4 pb-2">
        <b-col class="mb-3 mb-md-0">
          <h1 id="page-title" class="mb-0" v-if="$store.state.user">{{ $t('hello') }}, {{ $store.state.user.name }} 👋</h1>
        </b-col>
        <!--                <b-col md="auto">-->
        <!--                    <div class="video_modal_switcher d-flex justify-content-end" @click.stop="showVideo">-->
        <!--                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
        <!--                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 21.5994C14.546 21.5994 16.9878 20.588 18.7882 18.7876C20.5885 16.9873 21.6 14.5455 21.6 11.9994C21.6 9.45334 20.5885 7.01154 18.7882 5.21119C16.9878 3.41084 14.546 2.39941 12 2.39941C9.45389 2.39941 7.01209 3.41084 5.21174 5.21119C3.41139 7.01154 2.39996 9.45334 2.39996 11.9994C2.39996 14.5455 3.41139 16.9873 5.21174 18.7876C7.01209 20.588 9.45389 21.5994 12 21.5994ZM11.466 8.60101C11.2852 8.48044 11.0752 8.41119 10.8582 8.40065C10.6412 8.39011 10.4254 8.43867 10.2338 8.54117C10.0423 8.64366 9.88215 8.79623 9.77051 8.9826C9.65888 9.16898 9.59993 9.38216 9.59996 9.59941V14.3994C9.59993 14.6167 9.65888 14.8299 9.77051 15.0162C9.88215 15.2026 10.0423 15.3552 10.2338 15.4577C10.4254 15.5602 10.6412 15.6087 10.8582 15.5982C11.0752 15.5876 11.2852 15.5184 11.466 15.3978L15.066 12.9978C15.2303 12.8882 15.3651 12.7398 15.4583 12.5656C15.5515 12.3914 15.6002 12.1969 15.6002 11.9994C15.6002 11.8019 15.5515 11.6074 15.4583 11.4332C15.3651 11.2591 15.2303 11.1106 15.066 11.001L11.466 8.60101Z" fill="#9B9FA9"></path>-->
        <!--                        </svg>-->
        <!--                        <span class="ml-1">{{ $t('how_it_works') }}?</span>-->
        <!--                    </div>-->
        <!--                </b-col>-->
      </b-row>
      <b-row class="row-wide">
        <b-col md="auto">
          <div class="spacecard md left-sideblock">
            <h3 class="subform-h">{{ $t('quick_actions') }}</h3>
            <component :is="m.component"
                       class="home-link"
                       @click="handleFunctionCall(m.fun)"
                       v-for="(m, mind) in menu"
                       :to="m.to"
                       :key="'instr-menu-'+mind">
              <div class="home-link-title">
                <b-row class="align-items-center">
                  <b-col>{{ $t(m.title) }}</b-col>
                  <b-col cols="auto"><PlusSvg class="svg-icon-sm"/></b-col>
                </b-row>
              </div>
            </component>
          </div>
        </b-col>
        <b-col md>
          <StatBlock class="mt-md-0 mt-3" :statistics="statistics"/>
          <div class="spacecard">
            <div class="text-capitalize today">{{ now }}</div>
            <h2>{{ $t('today_schedule') }}</h2>
            <div class="classes">
              <div class="class-teaser" v-for="(lesson, lind) in lessons" :key="'lesson-'+lind">
                <b-row class="align-items-center">
                  <b-col cols="auto">
                    <div class="class-teaser__time">{{ lesson.start | moment('HH:mm') }}</div>
                    <div class="class-teaser__duration text-muted small">{{ lesson.duration }} {{ $t('min') }}</div>
                  </b-col>
                  <b-col cols="2">
                    <div class="text-center pl-4">
                      <b-button class="class-teaser__material" :id="'pop-'+lind" v-if="lesson.material">
                        <BookSvg class="svg-icon-sm"/>
                      </b-button>
                      <div v-else @click="addProgram(lesson)" class="add-program m-auto">
                        <PlusSvg class="svg-icon-sm"/>
                      </div>
                      <b-popover :ref="'popover'+lesson.material.id" v-if="lesson.material" :target="'pop-'+lind" triggers="hover" placement="top">
                        <div class="mb-3 font-weight-bold">{{ lesson.material.title }}</div>
                        <a class="btn-themed btn-white btn-sm" @click="preview(lesson.material)">{{ $t('preview') }}</a>
                      </b-popover>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="student-icon-wrapper" v-for="(student, sind) in lesson.students" :key="'stud-'+sind">
                      <!--                                        <img  class="student-icon" :src="student.image"/>-->
                      <LetterIcon :id="'imgpop-'+lind+'-'+sind" :obj="student" :title="student.name" class="student-icon sm"/>
                      <b-popover :target="'imgpop-'+lind+'-'+sind" triggers="hover" placement="top">
                        <b-row class="align-items-center">
                          <b-col cols="auto">
                            <LetterIcon :obj="student" :title="student.name" class="student-icon sm"/>
                            <!--                                                    <img class="student-icon sm" :src="student.image"/>-->
                          </b-col>
                          <b-col>
                            <div class="font-weight-bold">{{ student.name }}</div>
                            <div class="text-muted small text-capitalize">{{ student.level }}</div>
                          </b-col>
                        </b-row>
                      </b-popover>
                    </div>
                  </b-col>

                  <b-col cols="auto" v-if="$store.state.user">
                    <div class="position-relative" v-if="[1,3].includes($store.state.user.primary_role_id)">
                      <b-button class="btn-themed btn-start" v-if="!lesson.attendance_set" @click="startSession(lesson)">{{ $t('start') }}</b-button>
                      <div v-if="lesson.attendance_set" class="text-muted small">{{ $t('finished') }}</div>
                    </div>
                    <div class="position-relative" v-if="$store.state.user.primary_role_id == 4">
                      {{ lesson.instructor.name }}
                    </div>
                    <!--                  <div class="class-teaser__dots text-center rounded-circle">-->
                    <!--                    <DotsSvg/>-->
                    <!--                  </div>-->
                  </b-col>
                </b-row>
              </div>
              <div v-if="lessonsloaded && lessons.length < 1" class="text-center">
                <div class="no-lessons-icon">
                  <CalendarSvg/>
                </div>
                <div class="text-muted">
                  {{ $t('your_schedule_is_empty') }}
                </div>
                <div class="mt-3 pb-5">
                  <router-link :to="roleLinkAppendix()+'/schedule'" class="btn-themed">{{ $t('schedule') }}</router-link>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <AddStudentModal v-if="addstudentmodal"/>
      <InvitationSentModal user="student"/>
      <AssignTaskModal/>
      <AddPaymentModal v-if="addpaymentmodal"/>
      <SelectMaterial @selected="materialSelected"/>
      <PresentationPreviewModal v-if="showmodal" :material="material" :homework="false"/>
      <QuickCallModal/>
    </section>
    <!--      <TurnTest/>-->
  </div>
</template>

<script>
import videoSvg from '@/assets/svg/video.svg'
import { mapState } from 'vuex'
import BookSvg from "@/assets/svg/book2.svg"
import PlusSvg from "@/assets/svg/plus.svg"
import DotsSvg from "@/assets/svg/pointsv.svg"
import CameraSvg from "@/assets/svg/camera.svg"
import StudentSvg from "@/assets/svg/student.svg"
import CalendarSvg from "@/assets/svg/calendar.svg"
import EventBus from '@/services/event-bus'
import AddStudentModal from '@/views/pages/instructor/students/AddStudentModal'
import AddPaymentModal from '@/views/pages/instructor/payments/AddPaymentModal'
import QuickCallModal from '@/views/pages/instructor/schedule/QuickCallModal'
import InvitationSentModal from "@/views/pages/instructor/students/InvitationSentModal"
import StatBlock from '@/views/pages/instructor/payments/StatBlock'
import SelectMaterial from '@/views/parts/class/SelectMaterial'
import PresentationPreviewModal from '@/views/parts/class/preview/PresentationPreviewModal'
import LetterIcon from '@/views/parts/general/LetterIcon'
import { LessonsService, PaymentService } from '@/services/api.service'
import VideoModal from "../../parts/specific/VideoModal"
import moment from 'moment-timezone'
import AssignTaskModal from "@/views/pages/instructor/students/AssignTaskModal.vue"
// import TurnTest from "../../parts/specific/TurnTest"

export default {
  components: {
    AssignTaskModal,
    // TurnTest,
    CameraSvg,
    StudentSvg,
    CalendarSvg,
    DotsSvg,
    PlusSvg,
    BookSvg,
    videoSvg,
    AddStudentModal,
    AddPaymentModal,
    QuickCallModal,
    VideoModal,
    InvitationSentModal,
    SelectMaterial,
    PresentationPreviewModal,
    StatBlock,
    LetterIcon,
  },
  data() {
    return {
      teacher_menu: [
        // { component: 'router-link', title: "create_quick_lesson", to: '/lesson/1?host=1' },
        { component: 'router-link', title: "add_new_schedule", to: '/instructor/schedule' },
        { component: 'button', title: "add_student", to: '', fun: 'addstudent' },
        { component: 'button', title: "add_payment", to: '', fun: 'addpayment' },
        { component: 'button', title: "quick_call", to: '', fun: 'quickcall' },
        { component: 'button', title: "assign_task", to: '', fun: 'assignTask' },
      ],
      school_menu: [
        { component: 'router-link', title: "add_new_schedule", to: '/school/schedule' },
        { component: 'button', title: "add_student", to: '', fun: 'addstudent' },
        { component: 'button', title: "add_payment", to: '', fun: 'addpayment' },
        { component: 'button', title: "quick_call", to: '', fun: 'quickcall' },
      ],
      lessonsloaded: false,
      lessons: [],
      showmodal: false,
      selectedLesson: null,
      statistics: null,
      material: null,
      addstudentmodal: false,
      addpaymentmodal: false
    }
  },
  mounted() {
    EventBus.$emit('breadcrumbs',[])
    this.getTodaySchedule()
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if(modalId === "presentationpreviewmodal") {
        this.showmodal = false
      }
    })
    PaymentService.getStats().then(res => {
      this.statistics = res.data.data
    })
  },
  computed: {
    ...mapState({
      videoModalOpen: state => state.videoModalOpen,
    }),
    now() {
      return moment().format('DD MMM, ddd')
    },
    menu() {
      if(!this.$store.state.user) return this.teacher_menu
      let role_id = this.$store.state.user?.primary_role_id
      let m = this.teacher_menu
      if(role_id == 4) {
        m = this.school_menu
      }
      if(!this.$store.state.user?.can_admin_payments) {
        m = m.filter(function( obj ) {
          return obj.fun !== "addpayment"
        })
      }
      return m
    }
  },
  metaInfo() {
    return {
      title: this.$t('main'),
    }
  },
  methods: {
    showVideo() {
      this.$store.commit('setVideoModalOpen', true)
    },
    handleFunctionCall(function_name) {
      if (this[function_name]) {
        this[function_name]()
      }
    },
    addstudent() {
      this.addstudentmodal = true
      this.$nextTick(() => {
        this.$bvModal.show('addstudentmodal')
      })
    },
    addpayment() {
      this.addpaymentmodal = true
      this.$nextTick(() => {
        this.$bvModal.show('addpaymentmodal')
      })
    },
    getTodaySchedule() {
      LessonsService.get([], [], null, moment().format('YYYY-MM-DD'), this.$store.state.user.primary_role_id == 4).then(res => {
        this.lessons = res.data.data.lessons
        this.lessonsloaded = true
      })
    },
    startSession(lesson) {
      this.$router.push({name: 'Lesson', params: {id: lesson.id, code: lesson.code}})
      // this.$router.push({ name: 'Lesson', params: { id: id }, query: { host: 1 } })
    },
    addProgram(lesson) {
      this.selectedLesson = lesson
      this.$bvModal.show('selectmaterialmodal')
    },
    quickcall() {
      this.$bvModal.show('quick-call-modal')
    },
    assignTask() {
      this.$bvModal.show('assign-task-modal')
    },
    materialSelected(material) {
      LessonsService.update(this.selectedLesson.id, {material_id: material.id }).then(res => {
        let r = this.apiResponse(res)
        if(r.success) {
          this.$success(this.$t('class_updated'))
          this.getTodaySchedule()
        } else {
          this.$error(r.data)
        }
      })
    },
    preview(material) {
      this.material = material
      this.showmodal = true
      setTimeout(() => {
        this.$bvModal.show('presentationpreviewmodal')
      },50)
    },
  }
}
</script>

<style scoped lang="scss">
.today {
  color: $text-gray3;
}
.home-link {
  text-align: left;
  display: block;
  background: transparent;
  border: none;
  border-bottom: 1px solid $border-color2;
  box-sizing: border-box;
  padding: 20px 8px;
  width: 100%;
  font-weight: 500;
  color: $text-themed;
  svg {
    fill: $text-themed;
  }
  &:hover {
    text-decoration: none;
  }
  :last-of-type {
    border-bottom: none;
  }
}

.video_modal_switcher {
  position: relative;
  top: 0;
  cursor: pointer;
  user-select: none;
  &:hover {
    svg > path {
      fill: #43B4EB;
    }

    span {
      color: #43B4EB;
    }
  }

  span {
    font-size: 16px;
    color: #9B9FA9;
  }
}

.classes {
  margin-top: 25px;
}
.class-teaser {
  /*border-radius: 22px;*/
  border-bottom: 1px solid $border-color;
  padding: 20px 0;
  &__time {
    font-weight: 600;
    font-size: 18px;
    width: 50px;
  }
  .add-program {
    width: 40px;
    height: 40px;
    border: 1px solid $text-themed;
    text-align: center;
    line-height: 38px;
    cursor: pointer;
    border-radius: 100%;
    svg {
      fill: $text-themed;
    }
    &:hover {
      background: $text-themed;
      svg {
        fill: #fff;
      }
    }
  }
  &__material {
    border: 1px solid $border-gray;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    box-sizing: border-box;
    background: #fff;
    padding: 0;
  }

  &__student {
    margin-left: 20px;
    width: 40px;
    height: 40px;
  }
  &__dots {
    width: 28px;
    height: 28px;
    background: white;
  }
  .btn-start {
    display: none;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -23.5px;
  }
  .student-icon-wrapper {
    .student-icon {
      border: 1px solid #FFFFFF;
    }
    position: relative;
    display: inline-block;
    width: 26px;
  }
  &:hover {
    .btn-start {
      display: block;
    }
  }
}
.no-lessons-icon {
  width: 160px;
  height: 160px;
  border-radius: 100%;
  background: $almost-white;
  text-align: center;
  line-height: 160px;
  margin: 100px auto 40px auto;
  svg {
    width: 64px;
    height: 64px;
    fill: $border-gray;
  }
}

@media(max-width: 768px) {
  .home-link {
    padding: 10px 0;
  }
  .video_modal_switcher {
    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 14px;
    }
  }
}
</style>


